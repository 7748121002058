/* eslint-disable no-underscore-dangle */
import {combineReducers} from "redux";
import {paymentReducer as payment} from "@atg-payment-shared/money-page-domain";
import {modals} from "atg-modals/modalReducer";

const createRootReducer = (asyncReducers: any = {}) =>
    combineReducers<any, any>({
        // reducers that are lazily injected later will end up here
        ...asyncReducers.root,
        alert: () => window._frameStore.getState().alert,
        auth: () => window._frameStore.getState().auth,
        accessToken: () => window._frameStore.getState().accessToken,
        router: () => window._frameStore.getState().router,
        user: () => window._frameStore.getState().user,
        modals,
        modalData: () => window._frameStore.getState().modalData,
        payment,
        limits: () => window._frameStore.getState().limits,
        verticals: () => window._frameStore.getState().verticals,
    });

export default createRootReducer;
