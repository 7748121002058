import type {LazyStore} from "@atg-shared/lazy-store";
import {runLazySaga} from "@atg-shared/lazy-store";
import {fetchSaga} from "@atg-shared/fetch-redux";
import {kycQuestionnairePaymentSaga} from "@atg-aml-shared/kyc-domain";
import {
    DepositSaga,
    DepositFlowSaga,
    DepositAnalyticsSaga,
} from "@atg-payment-shared/deposit-domain";
import userSaga from "@atg-global-shared/user/userSaga";

export default function rootSaga(store: LazyStore<any, any>) {
    runLazySaga(store, fetchSaga);
    runLazySaga(store, kycQuestionnairePaymentSaga);
    runLazySaga(store, DepositSaga);
    runLazySaga(store, DepositFlowSaga);
    runLazySaga(store, DepositAnalyticsSaga);
    runLazySaga(store, userSaga);
}
