/* eslint-disable @typescript-eslint/no-implicit-any-catch */
import {merge} from "lodash";
import crossFetch from "cross-fetch";
import root from "window-or-global";
import * as Storage from "@atg-shared/storage";
import {AtgRequestError} from "@atg-shared/fetch-types";
import type {
    AtgErrorResponse,
    AtgGenericResponse,
    ErrorData,
    AtgResponse,
} from "@atg-shared/fetch-types";
import authorizationHeaderSupportedURLList from "./authorizationHeaderSupportedURLList";

type Headers = {
    "X-Requested-By": string;
    "X-Request-token": string;
    Accept: string;
    "Content-Type": string;
    "X-Client-Version"?: string;
    Authorization?: string;
};

type BaseOptions = {
    headers: Headers;
    credentials: RequestCredentials;
};

/**
 * A response via the `atg-fetch` fetch function
 * TODO: adjust types to include AtgGenericResponse<MultipleErrors>
 */
type AtgMaybeResponse<T> = AtgResponse<T> | AtgErrorResponse;

let sessionUUID: string;
const ACCESS_TOKEN_STORAGE_KEY = "ACCESS_TOKEN";

export const setSessionUUID = (_sessionUUID: string) => {
    sessionUUID = _sessionUUID;
};

export const addAuthHeaders = <T extends Partial<Headers>>(
    headers: T,
    ownToken = "",
): T & {Authorization?: string} => {
    const token = ownToken || Storage.getItem(ACCESS_TOKEN_STORAGE_KEY) || "";
    if (!token) return headers;

    return {
        ...headers,
        Authorization: `Bearer ${token}`,
    };
};

export const urlIsATGDomain = (url: string): boolean => {
    if (!url || url.length === 0) {
        return false;
    }

    let result = url;
    if (url.indexOf("https") > -1) {
        [, result] = url.split("https://");
    } else if (url.indexOf("http") > -1) {
        [, result] = url.split("http://");
    }
    const [domain] = result.split("/");

    if (!domain || domain.length === 0) {
        return true;
    }

    return domain.substring(domain.length - 7) === ".atg.se";
};

export function getBaseOptions(url = "", token?: string): BaseOptions {
    // TODO: Maybe we should move this into fetchAuthorized, because this is
    // only used when we're making an authorized request.
    //
    // Then we could throw an error if the requested URL is not in this list
    // which would make it easier for developers to understand why their request
    // is failing, instead of the request failing because we didn't add the auth
    // headers because it wasn't in the list.
    //
    // Also, when we add the auth headers inside atgFetch it's possible to make
    // authorized requests without using fetchAuthorized, as long as the user
    // already has a valid access token, but in the case when the token isn't
    // valid the request will fail without trying to refresh the token or
    // present the user with the login flow as fetchAuthorized would do.
    const isAuthorizationHeaderSupported =
        authorizationHeaderSupportedURLList.filter((u) => url.indexOf(u) > -1).length !==
        0;

    const clientVersions = root.clientVersions || "";
    let headers: Headers = {
        "X-Requested-By": root.requestedBy || "ATG",
        "X-Request-token": sessionUUID && sessionUUID,
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    if (!url.includes("/videoarkiv/")) {
        headers["X-Client-Version"] = JSON.stringify(clientVersions);
    }

    if (urlIsATGDomain(url)) {
        headers = isAuthorizationHeaderSupported
            ? addAuthHeaders(headers, token)
            : headers;
    }

    return {
        headers,
        credentials: "same-origin",
    };
}

export const serviceUrl = (url: string): string => {
    if (root.nativeServiceUrl) return root.nativeServiceUrl + url;
    return url;
};

const getTotalCount = (headers: Response["headers"]) => {
    const xTotalCountHeader: string | null | undefined = headers.get("x-total-count");
    if (!xTotalCountHeader) return null;
    return parseInt(xTotalCountHeader, 10);
};

export async function wrapResponse<T>(
    response: Response,
): Promise<AtgGenericResponse<T | {status: string}>> {
    let json;
    const data = await response.text();

    try {
        json = JSON.parse(data);
    } catch (err: any) {
        // TODO: This line has caused a lot of headaches and should probably be removed eventually
        //
        // We have many endpoints that don't return any data, but this line will make it look to the caller like data was returned. This makes downstream logic more complex as we sometimes need to check status codes and/or data structure unnecessarily.
        //
        // Partially related: https://jira-atg.riada.cloud/browse/TBET-473

        // status code 204 === "success without content", so there should be no data
        const result = response.status === 204 ? "SUCCEEDED" : "FAILED";
        json = {status: `${result} without data`};
    }

    return {
        data: json,
        meta: {
            code: response.status,
            statusText: response.statusText,
            totalCount: getTotalCount(response.headers),
        },
    };
}

const isSuccessResponse = <T>(
    response: AtgGenericResponse<T | ErrorData>,
): response is AtgGenericResponse<T> =>
    response.meta.code >= 200 && response.meta.code < 300;

export function checkStatus<T>(
    wrappedResponse: AtgGenericResponse<T> | AtgGenericResponse<ErrorData>,
): AtgMaybeResponse<T> {
    if (isSuccessResponse(wrappedResponse)) {
        return {
            ok: true,
            ...wrappedResponse,
        };
    }
    return {
        ok: false,
        ...wrappedResponse,
    };
}

export function throwIfError<T>(wrappedResponse: AtgMaybeResponse<T>): AtgResponse<T> {
    if (wrappedResponse.ok === true) return wrappedResponse;

    throw new AtgRequestError(wrappedResponse.meta.statusText, wrappedResponse);
}

/**
 * Could be a bit misleading.
 * This actually will handle any exception that occurs in wrapResponse function
 */
export function handleOffline(exception: Error): AtgErrorResponse {
    return {
        ok: false,
        meta: {
            isOffline: true,
            code: 0,
            statusText: "Nätverket är inte tillgängligt.",
            exception,
        },
        data: {
            status: "OFFLINE",
            message: "Nätverket är inte tillgängligt.",
        },
    };
}

/**
 * Similar to `window.fetch`, but will add authentication meta data when used against ATG backends.
 * Should only be used for trusted ATG endpoints.
 *
 * Note that it has a slightly different API than the native `fetch` (for example how it handles
 * errors).
 *
 * In order for access token to be included into the headers, please add your URL to the list: packages/shared/atg-fetch/authorizationHeaderSupportedURLList.ts
 */
export default function atgFetch<T>(
    url: string,
    options: Partial<RequestInit> = {},
    token?: string,
): Promise<AtgResponse<T>> {
    const decoratedOptions = merge(getBaseOptions(url, token), options);
    return (
        crossFetch(serviceUrl(url), decoratedOptions)
            .then((res) => wrapResponse<T>(res))
            .catch(handleOffline)
            // @ts-ignore
            .then((res) => checkStatus<T>(res))
            .then(throwIfError)
    );
}

/**
 * Has the same API as `atgFetch`, but will not add ATG specific custom HTTP headers. Can be used
 * for external APIs.
 */
export function pureFetch<T>(
    url: RequestInfo,
    options: Partial<RequestInit> = {},
): Promise<AtgResponse<T>> {
    return (
        crossFetch(url, options)
            .then((res) => wrapResponse<T>(res))
            .catch(handleOffline)
            // @ts-ignore
            .then((res) => checkStatus<T>(res))
            .then(throwIfError)
    );
}

// Used for fileBet upload
export function fetchFileUpload<T>(
    url: RequestInfo,
    options: Partial<RequestInit> = {},
): Promise<AtgResponse<T>> {
    return (
        crossFetch(typeof url === "string" ? serviceUrl(url) : url, {
            method: "POST",
            credentials: "same-origin",
            ...options,
            headers: {
                "X-Requested-By": root.requestedBy || "ATG",
                "X-Request-token": sessionUUID && sessionUUID,
                "X-Client-Version": JSON.stringify(root.clientVersions) || "",
                ...options.headers,
            },
        })
            .then((res) => wrapResponse<T>(res))
            .catch(handleOffline)
            // @ts-ignore
            .then((res) => checkStatus<T>(res))
            .then(throwIfError)
    );
}
